<template>
  <div class="visualVRouter">
    <div class="visualBox">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.visualVRouter {
  width: 100%;
  height: 100%;
  position: relative;
  .visualBox {
    width: 100vw;
    height: 100vh;
    background: url("~@/assets/images/analysis/home_bg.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
